/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext } from "react";
import { message as antdMessage } from "antd";
import mqttCli from "precompiled-mqtt";

export const MqttContext = createContext();

let client = null;

class MqttContextProvider extends React.Component {
	constructor() {
		super();
		this.state = {
			currentSceario: null,
			dataInstance: null,
		};
	}

	componentWillUnmount() {
		client.end();
	}

	/**
	 * Create a new scenario and return the result
	 * @returns {Promise<Obje>}
	 */
	getNewScenario = async () => {
		const url = "https://172.16.8.173:1235/platoon/replay/4ad71b18f64a4125/";

		const response = await fetch(url).catch((error) => {
			// eslint-disable-next-line no-console
			console.error(error);
		});
		const json = await response.json();

		return json;
	};

	/**
	 * Subscribe to given scenario id over mqtt
	 * @param {string} scenarioId
	 */
	subscribeToScenario = (scenarioId) => {
		if (client) {
			client.end();
		}
		client = mqttCli.connect(`mqtt://172.16.8.173:9001`);
		client.subscribe(`replay/platoon/${scenarioId}`, () => {
			client.on("message", (topic, message) => {
				this.setState({
					dataInstance: JSON.parse(message).data.PlatoonMembers,
				});
			});
		});

		antdMessage.loading(`Running the scenario: ${scenarioId} `, 2);
	};

	/**
	 * Create a new scenario and run
	 * @param {string} url
	 */
	runNewScenario = async () => {
		const data = await this.getNewScenario();

		const scenarioId = data.platoon.split("/").pop(); // get the last part of the url
		this.setState({ currentSceario: scenarioId });
		this.subscribeToScenario(scenarioId);
	};

	render() {
		return (
			<MqttContext.Provider
				// eslint-disable-next-line react/jsx-no-constructed-context-values
				value={{
					...this.state,
					runNewScenario: this.runNewScenario,
					subscribeToScenario: this.subscribeToScenario,
				}}
			>
				{this.props.children}
			</MqttContext.Provider>
		);
	}
}

export default MqttContextProvider;
