import { Button, Drawer, Space, Row, Divider, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import "./Drawer.css";
import { MqttContext } from "./store/MqttContext";

function DrawerComponent() {
	const [open, setOpen] = useState(false);
	const { currentSceario, runNewScenario, subscribeToScenario } =
		useContext(MqttContext);

	const [form] = Form.useForm();
	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		form.setFieldValue("scenario-id", currentSceario);
	}, [currentSceario, form]);

	const style = {
		padding: "8px 0",
		width: "100%",
		paddingLeft: "5px",
		paddingRight: "5px",
	};

	console.log(1);

	return (
		<>
			<Drawer
				placement="left"
				closable={true}
				onClose={onClose}
				open={open}
				height="auto"
				title={
					<Space style={{ display: "flex", alignItems: "center" }}>
						<SettingOutlined /> Settings
					</Space>
				}
			>
				<Row>
					<div style={style}>
						<Button onClick={runNewScenario} style={{ width: "100%" }}>
							Create & Run Scenario
						</Button>
						<Divider orientation="left" plain>
							Run a scenario
						</Divider>
						<Form
							form={form}
							name="customized_form_controls"
							layout="inline"
							onFinish={(formData) => {
								subscribeToScenario(formData["scenario-id"]);
							}}
							initialValues={{ "scenario-id": "" }}
						>
							<Form.Item name="scenario-id" label="Scenario ID:">
								<Input type="text" style={{ width: 100 }} />
							</Form.Item>
							<Form.Item style={{ flexGrow: "1", margin: 0 }}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: "100%" }}
								>
									Run
								</Button>
							</Form.Item>
						</Form>
					</div>
				</Row>
			</Drawer>

			<Button
				type="primary"
				onClick={showDrawer}
				className="ui black big launch right attached fixed button"
			>
				<i className="content icon" />
				<span className="text">Menu</span>
			</Button>
		</>
	);
}
// dasadasdasdasdas
export default DrawerComponent;
