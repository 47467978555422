/* eslint-disable no-unused-vars */
import "antd/dist/antd.min.css";
import React, { useContext, useEffect, useState } from "react";
import DeckGL from "@deck.gl/react";
import { MapboxOverlay } from "@deck.gl/mapbox/typed";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import Map, { NavigationControl, Popup, useControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { load } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { Modal } from "antd";
import Drawer from "./Drawer";
import { MqttContext } from "./store/MqttContext";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
mapboxgl.workerClass =
	// @ts-ignore
	// eslint-disable-next-line
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;

// Viewport settings
const INITIAL_VIEW_STATE = {
	latitude: 40.756119,
	longitude: -73.9745748,
	zoom: 15,
	pitch: 45,
	bearing: 0,
};

const getModel = () =>
	new Promise((resolve) => {
		load("/models/car/scene.gltf", GLTFLoader).then(resolve);
	});
//deneme asdasdsdasdsdasdasdasdas
function App() {
	const [vehicleCoords, setVehicleCoords] = useState({});
	const [showPopup, setShowPopup] = useState(false);
	const [popupCoords, setPopupCoords] = useState(null);

	const { dataInstance } = useContext(MqttContext);

	useEffect(() => {
		if (!dataInstance) return;

		const frame = dataInstance;
		// eslint-disable-next-line no-underscore-dangle
		const _newData = {};
		Object.keys(frame).forEach((key) => {
			const { Latitude, Longitude } = frame[key];
			_newData[key] = [Longitude, Latitude, 0]; // long, lat, heading
		});

		// calculate heading from previous frame
		setVehicleCoords((oldCoords) => {
			Object.keys(_newData).forEach((key) => {
				//aracın heading yönünü hesaplıyor. aracın gidiş yönüne göre kafasının location hesaplıyoruz.
				const previous = oldCoords[key];
				if (previous) {
					const A = _newData[key];
					const B = previous;
					const angle =
						((-(Math.atan2(B[0] - A[0], B[1] - A[1]) * (180 / Math.PI)) % 360) +
							360) %
						360;
					_newData[key][2] = angle;
				}
			});
			return { ...oldCoords, ..._newData };
		});
	}, [dataInstance]);

	const layers = [
		new ScenegraphLayer({
			id: "scene-layer",
			data: Object.values(vehicleCoords),
			scenegraph: getModel(),
			getPosition: (d) => d.slice(0, 2),
			getOrientation: (d) => [0, d[2] + 90, 90],
			sizeScale: 0.02,
			_lighting: "pbr",
			getTranslation: [5, -20, 0],
			// transitions: { getPosition: { duration: 500 } }, // TODO: add this later for smooth animation
			// Enable picking
			pickable: true,
			onClick: (info, event) => {
				// console.log("Clicked:", info, event);
				console.log({ info, event });
				const [longitude, latitude] = info.coordinate;
				setPopupCoords({ longitude, latitude });
				setShowPopup(true);
			},
		}),
	];

	return (
		<>
			<DeckGL initialViewState={INITIAL_VIEW_STATE} controller layers={layers}>
				<Map
					mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
					mapStyle="mapbox://styles/ocakivenit/cl831edh3002v14l59texkhwj"
				/>
			</DeckGL>
			<Modal
				title="{Vehicle ID}"
				style={{
					top: 20,
				}}
				visible={showPopup}
				onCancel={() => setShowPopup(false)}
				mask={false}
				width={350}
				footer={null}
			>
				<p>some contents...</p>
				<p>some contents...</p>
				<p>some contents...</p>
			</Modal>
			<Drawer />
		</>
	);
}

export default App;
